@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);

html {
  /*
  overflow: hidden !important;
  */
}

body {
  color: #8a6b70;
  /*background-color: lightgrey;*/

  height: 100%; /*Esto arregla el problema de scroll up en firefox*/
  width: 100%;
  overflow: hidden;
}
body > ui-view {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

/*Esto ayuda a que el contenido de los formularios no se corte por abajo en monitores con poca alura.*/
/*Puede que haya una solución mas permanente o elegante.*/
/*Diria que el problema de verdad tiene algo que ver con que la altura de la pagina no este teniendo en cuenta el hueco que ocupa la barra de navegacion superior*/
main {
  margin-bottom: 100px !important;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}

a {
  color: #59d21c;
  text-decoration: none
}

.primaryColor {
  color: #59d21c;
}

/*COLOR DE LOS FILTROS Y PLACEHOLDERS*/
.filterColor {
  color: #757575;
}

.filterColor::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #757575;
}

.filterColor:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #757575;
  opacity: 1;
}

.filterColor::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #757575;
  opacity: 1;
}

.filterColor:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #757575;
}

/*FIN COLOR DE LOS FILTROS Y PLACEHOLDERS*/

.headerSize {
  font-size: 16px;
  color: #59d21c;
}

.emptyList {
  color: #9e9e9e;
  font-style: italic;
  text-align: center;
  font-size: larger;
  margin: 40px 0;
}

.loginInputInside {
  border: none;
  background: none;
  padding: 15px 20px 15px 0;
}

.impactSelectDivisa {
  max-width: 56px;
}

.iconInput {
  padding: 0 0 0 20px;
  font-size: 18px;
}

.loginInputOutside {
  width: 300px;
  margin: 10px 0;
  border: 1px solid #DDD;
  border-radius: 8px;
  background: #ffffff;
}

.loginButton {
  background: #59d21c;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 15px 10px;

  display: block;
  width: 100%;
  margin: 10px 0 0 0;
}

.primaryButton {
  background: #59d21c;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 7px 18px;

  display: block;
  width: 110px;
  margin: 20px 10px 20px 0;
}
.primaryButton.disabled {
  opacity: 0.6;
}

.secondaryButton {
  background: #8a6b70;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 7px 18px;

  display: block;
  width: 110px;
  margin: 20px 10px 20px 10px;
}

.forgotPass {
  margin: 20px 0 20px 25px;
}

.toolbarImpact {
  position: fixed;
  background: #fff !important;
}

/*
.logoToolbar {
  margin: 0px 20px 10px;
  width: 114px;
  height: 80px;
}
*/

.logoToolbar {
  width: 130px;
  margin: 20px;
}


.mainBackground {
/*
  background: #f2f2f2;
  */
  background: white;
}

.below-top {
  display: block;
  padding-top: 80px!important;
}

.marginTopLogin {
  margin-top: -70px;
}

.entity-table {
  margin: 0 0%; /*antes 5%*/
  /*max-width: 1200px;*/
}

.allWidth {
  width: 100%;
}

.form-table {
  max-width: 930px;
  margin: 0 auto;
}

.form-center-column {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.form-center-column:only-child {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  margin-left: 120px;
  margin-right: 120px;
}

.form-side-column {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 120px;
          flex: 0 0 120px;
}

@media screen and (min-width: 1600px) {
  .form-side-column {
    -ms-flex-preferred-size: 300px;
        flex-basis: 300px;
  }

  .form-center-column:only-child {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    margin-left: 300px;
    margin-right: 300px;
  }
}

md-list-item:hover {
  /*color:#59d21c;*/
  background: #f0eeee;
  border-left-color: #59d21c;
}

md-list-item {
  border-left: solid;
  border-left-color: #323232;
}

.filterBox {
  border: 1px solid #DDD;
  background: #ffffff;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  box-sizing: border-box;
}

.filterContribution {
  margin: 0 !important;
}

/*fix para la pantalla contribution*/
.filterContribution > impact-select > md-menu > div > input {
  width: 75% !important;
}

.noPadding {
  padding: 0 !important;
}

.customDatePicker {
  width: 67% !important;
  display: inline-block;
}

.customDatePicker > button {
  height: 0 !important;
  padding: 0 !important;
}

.customDatePicker > div {
  border-bottom-style: none !important;
}

.customDatePicker > div > input {
  padding: 3px 0 0px !important;
}

.md-datepicker-triangle-button {
  /*visibility: hidden;*/
}

.md-datepicker-input-container {
  width: 75% !important;
}

.filterBox > label {
  margin: 2px 5px 0 0;
}

.filterBoxDisabled {
  border: none !important;
}

.notSort {
  /*padding: 0 0 0 24px;*/
}

.filterInput {
  min-width: 30px;
  border: none;
  background: none;
  width: 100%;
  /*added*/
  /*font-size: 12px;*/
  min-height: 20px;

  /*added por roberto*/
  /*
  border-right-style: dotted;
  border-right-width: thin;
  border-right-color: gray;
  border-left-style: dotted;
  border-left-width: thin;
  border-left-color: gray;
  padding-left: 5px;
*/
}

.filterInput:not([disabled]) {
  min-width: 30px;
  border: none;
  background: none;
  width: 100%;
  /*added*/
  /*font-size: 12px;*/
  min-height: 20px;

  /*added por roberto*/
  border-right-style: dotted;
  border-right-width: thin;
  border-right-color: gray;
  border-left-style: dotted;
  border-left-width: thin;
  border-left-color: gray;
  padding-left: 5px;

}

.iconoOrdenacion {
  cursor: pointer;
}

.listEntidadesImpactos {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.listEntidadesImpactos > li {
  cursor: pointer;
  /*border: 1px solid;*/
  padding: 10px;
  margin-left: 15px;
  margin-bottom: 5px;

  -webkit-clip-path: polygon(0% 0%, 85% 0, 100% 50%, 85% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 85% 0, 100% 50%, 85% 100%, 0% 100%);
  background-color: #ededed;
}

.entidadImpactosSelected {
  /*color: #59d21c;*/
  margin-left: 0 !important;
  font-weight: bold;

  background-color: #59d21c !important;
  color: white;
}

.selectInput {
  border: none;
  background: none;
  width: 85%;
}

.listHeader {
  padding-bottom: 20px;
}

.ul-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.overflowText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflowTextSingleline {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.entity-list-item {
  font-size: 14px;
  border-left: 3px solid transparent;
}
.entity-list-header {
  margin-bottom: 10px;
}
.entity-list-item-striped {
  border-color: #323232;
  background: white;
  margin-bottom: 3px;
  min-height: 50px;
}
.entity-list-item-striped:hover {
  border-color: #59d21c;
  background: #e7dddd;
}
.entity-list-item > [flex] {
  /*border: none;*/
  margin: 0 5px;
  padding: 5px;
  box-sizing: border-box;
}

.entity-list-header > [flex] {
  border: 1px solid #e7dddd;
}
.entity-list-item-striped > [flex] {
}

.entity-list-item > [flex]:last-child {
  width: 130px;
  border: none;
}

/**/

.entity-list-item-contributions {
  border-left: none !important;
  border-top: 1px solid #e0e0e0;
  /*border-color: #323232;*/
  /*background: white;*/
  /*margin-bottom: 3px;*/
}

entity-list-item-contributions:last-child {
  border-bottom: 1px solid #e0e0e0;
}

.entity-list-item-contributions:hover {
  /*border-color: #59d21c;*/
  background: #e7dddd;
}

.entity-list-item-contributions > [flex] {
}

.md-open-menu-container {
  width: auto !important; /*TODO esto tarde o temprano tneemos que quitarlo y buscar una solucion mejor*/
}

/**/

.contributionsMoreLess {
  width: 5px;
  height: 32px;
  border-width: 3px;
  border-right: solid
}

.buttonNew {
  color: #ffffff;
  border-radius: 5px;
  background-color: #59d21c;
  width: 190px;
  /*height: 40px;*/
}

.planBox {
  background: white;
  width: 100%;
  margin: 10px 0 20px 0;
}

.planHeader {
  background: #c59f54;
  color: white;
  padding: 10px 0;
  text-align: center;
}

.planContent {
  padding: 0 40px 20px 40px;
}

.planElement {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 5px 0;
}
.planElement-title {
  margin-right: .5em;
}
.planElement-data {
  min-width: 50px;
}

.miniInput2 {
  width: 32% !important;
}

.miniInput1 {
  width: 25% !important;
}

.rightLogo {
  max-height: 45px;
  max-width: 120px;
  margin: 0 1em;
}

.userCount {
  margin: 0 0 10px 10px;
}

input[type="number"] {
  text-align: right;
}

.dividerInitiativeForm {
  margin: 15px 0;
  border-top-width: 5px;
}

.proyectInitiatives {
  padding-left: 0px;
  position: absolute;
  display: none;
  background-color: white;
  z-index: 100;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.proyectInitiatives > li {
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: block;
  cursor: pointer;
}

li:hover .proyectInitiatives {
  display: block;
}

/*.proyectInitiatives:hover {*/
/*display: block;*/
/*}*/

.esNumerico {
  text-align: right;
}





.impactForm .layout-align-space-around-center.layout-row .filterBox.campoSelect label {
  width: 49%;
}

.impactForm .layout-align-space-around-center.layout-row .filterBox.campoSelect .selectInput {
  width: 46%
}

.impactForm .layout-align-space-around-center.layout-row .campoNumber label {
  width: 70%;
  display: inline-block;
}

.impactForm .layout-align-space-around-center.layout-row .campoNumber .filterInput {
  width: 26%;
  display: inline-block;
}

.impactForm .filterInput {
  width: 63%;
}

.impactForm .filterInput2 {
  width: 45%;
}

.impactForm .allWidth {
  width: 49%;
}

.impactForm .filterBox {
  margin: 10px 0;
  padding: 8px 15px;
}

.impactForm .filterBox.filterBoxDisabled {
  /*background-color: inherit;*//**TODO este cambio lo han pedido pero alomjor no esta claro en todos los sitios*/
  border: 1px solid #DDD !important;
}

.impactForm .iconoOrdenacion {
  /*padding-left: 7px;*/
}

.impactForm .layout-align-space-around-center.layout-row .campoFecha label{
  width: 20%;
  display: inline-block;
}

md-toolbar > md-nav-bar > .md-nav-bar {
  background-color: transparent;
}

md-toolbar > md-nav-bar.md-default-theme > .md-nav-bar .md-button._md-nav-button, md-toolbar > md-nav-bar > .md-nav-bar .md-button._md-nav-button {
  color: rgba(0,0,0,0.54);
}

.md-button.md-default-theme.md-accent, .md-button.md-accent {
  color: rgb(138,107,112);
}

button.md-button.md-ink-ripple {
  white-space: normal;
}

md-menu-item.ng-scope {
  height: auto;
}

.md-open-menu-container{
  width: 300px;
}

.whiteBackground {
  background-color: white;
}

.indent {
  margin: 10px 0px 10px 30px;
}

.totalBox {
  margin: 15px 0;
  padding: 10px 0;
  border-style: solid;
  border-width: 1px;
}






.header{
  width: 100%;
}

.headerLeft{
  display: inline-block;
  width: 79%;
  vertical-align: top;
}

.headerLeft img, .headerLeft md-nav-bar{
  display: inline-block;
  vertical-align: bottom;
}

.headerRight{
  display: inline-block;
  width: 19%;
  text-align: right;
  vertical-align: top;
  margin-top: 20px;
}

.header-user-section{
  font-size: 15px;
  color: #8a6b70;
  margin: .5em;
}

.logout{
  color: #59d21c;
  margin-left: 20px;
  cursor: pointer;
}

.infoProfile{
  display: inline-block;
  vertical-align: top;
  padding-top: 15px;
}

impact-select,
impact-multi-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  cursor: pointer;
}
.impact-select-filter-container {
  z-index: 10;
  background: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.impact-select-filter {
  padding: .5em;
  width: 100%;
  margin: 4px;
  border: 1px solid #ccc;
  background: none;
  min-height: 1em;
  box-sizing: border-box;
}

.campoSelect impact-select input {
  width: 100% !important;
}

.header-select {
  background: none;
  border: none;
  margin-right: 5px;
}

.cif-label {
  position: relative;
}
.cif-label:after {
    position: absolute;
    left: 36px;
    top: 0px;
    opacity: 0.5;
    pointer-events: none;
    content: 'A0000000X';
}
.cif-label + input {
  padding-left: 3px;
}

md-toast {
  position: fixed !important;
}

/** Añadido Rober 16/06 **/
section.layout-column{
  min-width: 345px;
}

.filterBox.filterContribution {
  /*width: 105px;*/
}

.colDateContr md-icon.iconoOrdenacion.material-icons {
  font-size: 20px;
}

.colDateContr input.md-datepicker-input {
  font-size: 11px;
  /*margin-left: 30px;*/
}

.logoImpactLogin{
  width: 300px;
}

/** Bordes de las tablas **/

.entity-list-item-striped:first-child{
  border-top-style: solid;
  border-top-width: 0.5px;
  border-top-color: grey;;
}

.entity-list-item-striped{
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  border-bottom-color: grey;;
}

/** Contribuciones **/

.icon-add-contr{
  color: #59d21c;
}

/** Estilo general de la web **/

.icon-plus{
  color:#59d21c;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.contributionButton {
  margin: 0 !important;
  padding: 0 !important;
  width: 24px !important;
  height: 24px !important;
}

.contributionIcon {
  margin: 0 !important;
  color: #8a6b70 !important;
}

.contributionCell {
  margin: 0 !important;
  padding: 5px 2px !important;
}

.atributionLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contributionHeader {
}

.contributionHeaderDate {
  min-width: 117px !important;
  max-width: 117px !important;
  width: 117px !important;
  margin: 0px !important;
}

.entity-list-item-contributions > [flex] {
}

.contributionImageIcon {
  /*color:#59d21c;*/
  /*vertical-align: middle;*/

}

.toolbartopNavbar {
  margin-top: 16px
}

.toolbartopNavbar > .md-nav-bar {
  border-style: none !important;
}

.toolbartopNavbar > div > nav > ul > li > a > span {
  font-size: 16px;
}

.dataColumn {
  min-width: 450px;
}


.mas-button-add {
  background: #59d21c !important;
  text-transform: none;
  color: white;
  font-weight: bold;
  border: 3px solid transparent;
  box-sizing: border-box;
  border-radius: .5em;
}
.mas-button-add:hover {
  border: 3px solid #ccc;
}

.contribution-5-digit-field {
  max-width: 70px;
  width: 70px;
}

.button-form-section {
  margin-bottom: 6em;
}


.impact-save-button {
  padding: 0 !important;
  border-radius: 0 !important;
}
.impact-save-icon {
  color: dodgerblue;
  font-size: 3em;
}

.md-scroll-mask {
  z-index: 0 !important;
}

.initiative-form > h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.initiative-form > div > h2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* añadido por juan el 17/10/17 a las 14:27
   para hacer sitio al boton de estadisticas
*/
.toolbartopNavbar ._md-nav-button {
  padding: 12px 8px;
}

/* usar esta clase para ocultar el boton de estadisitcass */
.nav-item-reporte-web {

}

.md-datepicker-input {
  color: #757575;
}

.md-datepicker-input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #757575;
  opacity: 1; /* Firefox */
}

.md-datepicker-input:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #757575;
  opacity: 1; /* Firefox */
}

.md-datepicker-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #757575;
  opacity: 1; /* Firefox */
}

.md-datepicker-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #757575;
}

.md-datepicker-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #757575;
}

/* infinite scroll */

.infinite-scroll-container {
    min-height: 300px;
    max-height: 636px;
    overflow-y: scroll;
  }
#activity-history-dialog .infinite-scroll-container{
    max-height: inherit;
}

/*
.infinite-scroll-container::-webkit-scrollbar {
  width: 0px;  // remove scrollbar space
  background: transparent;  // optional: just make scrollbar invisible
}
*/

.text-right {
  text-align: right;
}

.info-link-container {
  text-align: right;
  padding-right: 50px;
}

.info-link-icon {
  color: #59d21c;
}

.info-link-icon:hover {
  color: rgba(0, 0, 0, 0.54);
}


.ods-chart-logo-container {
  margin: 10px 10px;
}

.ods-chart-logo-container img {
  display: block;
}

.ods-chart-percentaje-bar {
  height: 20px;
  /*width: 1000px;*/
  /*background-color: #b2b2ff;*/
  /*border: 1px solid blue;*/
  color: white;
  margin-bottom: 10px;
}

.ods-chart-percentaje-container {
  width: 150px;
  margin: 0 10px;
  text-align: center;
}

/*ODS percentaje colors*/

/*estructure is: .rowX .class:nth-child {color}*/

/*first row*/

.row1 .ods-chart-percentaje-container:nth-child(1) {
  background-color: #E5233D;
}
/*fin pobreza*/

.row1 .ods-chart-percentaje-container:nth-child(2) {
  background-color: #DDA73A;
}
/*hambre cero*/

.row1 .ods-chart-percentaje-container:nth-child(3) {
  background-color: #4CA146;
}
/*salud y bienestar*/

.row1 .ods-chart-percentaje-container:nth-child(4) {
  background-color: #C7212F;
}
/*educacion calidad*/

.row1 .ods-chart-percentaje-container:nth-child(5) {
  background-color: #EF402B;
}
/*igualdad de genero*/

.row1 .ods-chart-percentaje-container:nth-child(6) {
  background-color: #27BFE6;
}
/*saneamiento*/


/*second row*/

.row2 .ods-chart-percentaje-container:nth-child(1) {
  background-color: #FBC412;
}
/*energia asequible y no contaminante*/

.row2 .ods-chart-percentaje-container:nth-child(2) {
  background-color: #A21B43;
}
/*trabajo decente y crecimiento economico*/

.row2 .ods-chart-percentaje-container:nth-child(3) {
  background-color: #F26A2C;
}
/*industria innovacion e infraestructura*/

.row2 .ods-chart-percentaje-container:nth-child(4) {
  background-color: #DE1768;
}
/*reduccion de las desigualdades*/

.row2 .ods-chart-percentaje-container:nth-child(5) {
  background-color: #F89D28;
}
/*ciudades y comunidades disponibles*/

.row2 .ods-chart-percentaje-container:nth-child(6) {
  background-color: #BF8D2C;
}
/*produccion y consumo responsables*/


/*third row*/

.row3 .ods-chart-percentaje-container:nth-child(1) {
  background-color: #407F46;
}
/*accion por el clima*/

.row3 .ods-chart-percentaje-container:nth-child(2) {
  background-color: #1D98D4;
}
/*vida submarina*/

.row3 .ods-chart-percentaje-container:nth-child(3) {
  background-color: #59BA47;
}
/*vida ecosistemas terrestres*/

.row3 .ods-chart-percentaje-container:nth-child(4) {
  background-color: #136A9F;
}
/*paz justicia e insituciones solidas*/

.row3 .ods-chart-percentaje-container:nth-child(5) {
  background-color: #14496B;
}
/*alianzas para lograr los objetivos*/

.row3 .ods-chart-percentaje-container:nth-child(6) {
  background-color: transparent;
}
/*alianzas para lograr los objetivos*/

.nobold {
      font-weight: normal !important;
}



/* ACTIVITY HISTORY */
activity-history li.entity-list-item-striped.entity-list-item.ng-scope.layout-align-center-center.layout-row {
  text-align: center;
}

md-dialog#activity-history-dialog {
  width: 90%;
  height: 90%;
}

md-dialog#activity-history-dialog .md-toolbar-tools {
  background-color: #59d21c;
  color: white;
}
md-dialog#activity-history-dialog .md-toolbar-tools h2{
  text-align: center;
  width: 100%;
}
.percent-selector {
  margin: auto;
}
.percent-selector .md-select-value{
  min-height: 20px;
}
#modalSessionOut {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
#modalSessionOut-content {
  background-color: #fefefe;
  margin: 35vh auto;
  padding: 20px;
  border: 1px solid #888;
  width: 275px;
  text-align:center;
}
.contributionsMoreLess{
    visibility: hidden;
}
.contributionsMoreLess[style*="border-color: green"] + .contributionCell > .filterContribution {
    border: 1.5px solid green;
}
.contributionsMoreLess[style*="border-color: red"] + .contributionCell > .filterContribution {
    border: 1.5px solid red;
}
.contributionsMoreLess + .contributionCell > .filterContribution input {
    text-align: center;
    font-size: 10px;
}
.clasificationScreen ol .ng-scope .filterBox span.lang {
    margin-left: -5px;
    margin-right: 5px;
	width: 21px;
    display: inline-block;
    text-align: center;
}
impact-multi-select[values="vm.data.lstImpactoParticipantes"] > md-menu {
    max-width: calc(100vw - 785px);
}
.hidden{
	display:none!important;
}
.visibilityhidden{
	visibility:hidden!important;
}

.accordion {
  background-color: transparent;
  color: #444;
  cursor: pointer;
  padding: 11px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  padding: 11px 15px;
}

.active, .accordion:hover {
  background-color: transparent;
}

.accordion:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2359d21c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");	
  content: '';
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}

.active:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.panel {
  padding: 0;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

#langContainer.isLogin{
	margin-bottom: 18.5px;
}

breadcrumb, breadcrumb span {
    color: #59d21c;
    font-size: 16px;
}
.below-top main .md-margin.layout-row {
    margin-top: 0;
}

.downloadReportButton{float:right;}
.downloadReportButton i, .downloadReportButton span{vertical-align:middle;}


.report-filter {
  max-width: 1200px;
  margin: auto;
  margin-bottom: 4em;
  padding: 1em;
}
.report-filter .md-title {
  margin-top: 2em;
}

.report-filter-checkbox {
  margin-right: 0;
  padding-right: 1em;
  box-sizing: border-box;
}
.report-filter-check-buttons {
  margin-bottom: .5em;
}
.report-filter-check-container {
  margin-left: 1em;
}
.report-filter-divisa {
  max-width: 140px;
  margin: 1em .5em;
}
.report-filter-divisa label {
  margin-right: .5em;
}

.report-filter input {
  width: 100%;
  text-align: right;
}
.report-filter impact-multi-select .filterColor {
  width: 100%;
  text-align: right;
}


.report-filter .md-datepicker-input-container {
  border-bottom-color: transparent;
}
.report-filter-date-button {
  padding: 0 !important;
  height: 24px !important;
  min-height: 24px !important;
}

.report-filter-dates .md-body-2 {
  margin: 0;
  margin-top: 15px;
  margin-left: .5em;
}

.report-filter-error {
  color: indianred;
}

.report-filter-loading {
  font-size: 40px;
  margin: 1em 0;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


.report-chart-title {
  color: #00719c;
  border-bottom: 3px solid #00719c;
  padding: 5px;
  font-size: 18px;
}
.report-chart-subtitle {
  margin: 0 auto;
  margin-top: 30px;
  width: 90%;
  font-size: 16px;
  border-bottom: 3px double currentColor;
}
.report-table {
  /* width: 90%; */
  margin: 1em auto;
  /*border-bottom: 1px solid #ccc;*/
}
.report-table th {
  background-color: #ffffff;
  color: #002060 !important;
  border-bottom: solid 2px #ccc !important;
}
.report-table th {
  white-space: normal !important;
}
.report-table td {
  white-space: nowrap !important;
  color: #00719c !important;
}
.report-table th,
.report-table td {
  /*border-right: 1px solid #ccc;*/
  font-size: 15px !important;
  text-align: center !important;
  box-sizing: border-box;
  padding: 12px !important;
}
.report-table .small-cell {
  width: 105px;
}
.report-table .currency-cell {
  width: 150px;
  box-sizing: border-box;
}

.report-table tr:last-child td {
  font-weight: bold;
  color: #0070c0;
}

.report-table td:first-child {
  text-align: left !important;
}

.report-table th.subtitle-reports  {
    font-size: 18px !important;
    text-align: left !important;
    border-bottom: none !important
}

.report-table thead tr:first-child{
  display: none;
}
.report-table thead tr:last-child th:not(:first-child){
  max-width: 40px;
  vertical-align: bottom;

}
.report-table tr.md-row th:not(:first-child) span{
  -webkit-writing-mode: tb-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: tb-rl;
  -webkit-transform: rotate(220deg);
          transform: rotate(220deg);
  max-height: 130px;
}


.report-filter {
  max-width: 1200px;
  margin: auto;
  margin-bottom: 4em;
  padding: 1em;
}
.report-filter .md-title {
  margin-top: 2em;
}

.report-filter-checkbox {
  margin-right: 0;
  padding-right: 1em;
  box-sizing: border-box;
}
.report-filter-check-buttons {
  margin-bottom: .5em;
}
.report-filter-check-container {
  margin-left: 1em;
}
.report-filter-divisa {
  max-width: 140px;
  margin: 1em .5em;
}
.report-filter-divisa label {
  margin-right: .5em;
}

.report-filter input {
  width: 100%;
  text-align: right;
}
.report-filter impact-multi-select .filterColor {
  width: 100%;
  text-align: right;
}


.report-filter .md-datepicker-input-container {
  border-bottom-color: transparent;
}
.report-filter-date-button {
  padding: 0 !important;
  height: 24px !important;
  min-height: 24px !important;
}

.report-filter-dates .md-body-2 {
  margin: 0;
  margin-top: 15px;
  margin-left: .5em;
}

.report-filter-error {
  color: indianred;
}

.report-filter-loading {
  font-size: 40px;
  margin: 1em 0;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

